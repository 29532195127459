import request from "@/util/request";

export const getCommunityList = (pageNum, params) => {
  return request({
    url: `/new_gateway/codepower-rest-api/community/list/${pageNum}/10`,
    method: "get",
    params,
  });
};


export const getCommunityDetail = (id) => {
  return request({
    url: `/new_gateway/codepower-rest-api/community/get/${id}`,
    method: "get",
  });
};

export const joinCommunity = (data) => {
  return request({
    url: `/new_gateway/codepower-rest-api/community/join`,
    method: "post",
    data,
  });
};

export const checkJoinCommunity = (id) => {
  return request({
    url: `/new_gateway/codepower-rest-api/community/checkJoin/${id}`,
    method: "post",
  });
};

export const getMyApplyInfo = () => {
  return request({
    url: `/new_gateway/codepower-rest-api/apply/getMyApplyInfo`,
    method: "get",
  });
};


export const getCommunityProvinceCount = (province) => {
  return request({
    url: `/new_gateway/codepower-rest-api/community/province/${province}`,
    method: "post",
  });
};

export const createCommunity = (data) => {
  return request({
    url: `/new_gateway/codepower-rest-api/community/create`,
    method: "post",
    data,
  });
};
